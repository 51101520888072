<template>
  <div class="row">
    <div class="col-12">
      <div class="bg-light-primary rounded-top py-half px-1">
        <div class="psh-header mb-0 d-flex align-items-center px-0">
          <div class="psh-title border-0 mr-0">
            <i class="icon icon-psh-customer text-h1" style="font-size: 2rem;" />
          </div>
          <h4 class="card-title mb-25 text-primary ml-1 flex-grow-1">
            Customer
          </h4>
          <span v-if="false && !isCurrentOrderShipped">
            <strong v-if="!isEditable" class="mx-1 text-button" role="button" @click="openAddressForm">
              Edit
            </strong>
            <strong v-else class="mr-1" role="button" @click="isEditable = false">
              Cancel
            </strong>
          </span>
        </div>
      </div>
      <div v-if="!isEditable" class="row border rounded-bottom p-half mx-0">
        <div class="col-6">
          <div>
            <p-icon name="bi-person" /> {{ fullName }}
          </div>
          <div>
            <p-icon name="bi-phone" /> {{ phone }}
          </div>
          <div class="ellipsis">
            <p-icon name="bi-at" /> {{ email }}</div>
        </div>
        <div class="col-6">
          <div>
            <p-icon name="bi-house" /><span class="text-muted mr-1"> Shipping Address</span>
            <div v-if="!isEditable" class="">
              {{ shippingAddress }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="border rounded-bottom p-half">
        <div class="col-12">
          <p>
            <p-icon name="bi-person" /> {{ fullName }}
          </p>
          <div>
            <div>
              <dx-util-form
                ref="addressFormRef"
                :form-data="addressForm"
                :col-count="1"
                :show-colon-after-label="true"
                label-mode="floating"
                label-location="top"
              >
                <dx-util-item editor-type="dxTextBox" data-field="address1">
                  <dx-util-required-rule message="Street is required" />
                </dx-util-item>
                <dx-util-group-item :col-count="2" :col-span="1">
                  <dx-util-item editor-type="dxTextBox" data-field="address2" />
                  <dx-util-item editor-type="dxTextBox" data-field="phone" />
                </dx-util-group-item>
                <dx-util-group-item :col-count="2" :col-span="1">
                  <dx-util-item editor-type="dxTextBox" data-field="city">
                    <dx-util-required-rule message="City is required" />
                  </dx-util-item>
                  <dx-util-item editor-type="dxTextBox" data-field="postcode">
                    <dx-util-required-rule message="Zipcode is required" />
                  </dx-util-item>
                  <dx-util-item editor-type="dxTextBox" data-field="state">
                    <dx-util-required-rule message="State is required" />
                  </dx-util-item>
                  <dx-util-item editor-type="dxTextBox" data-field="country">
                    <dx-util-required-rule message="Country is required" />
                  </dx-util-item>
                </dx-util-group-item>
              </dx-util-form>
              <div class="d-flex pt-1">
                <div class="w-50 pr-half">
                  <dx-util-button class="d-block d-inline" type="danger" text="Cancel" @click="isEditable = false" />
                </div>
                <div class="w-50 pl-half">
                  <dx-util-button class="d-block d-inline" type="success" text="Save" @click="updateShippingAddress" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import fbmService from '@/http/requests/fbm/fbmService'
import useFbmState from '../useFbmStates'

export default {
  setup() {
    const { currentOrder, isCurrentOrderShipped, getFbmOrder } = useFbmState()

    const fullName = computed(() => `${currentOrder?.shippingAddress?.firstName || ''} ${currentOrder?.shippingAddress?.lastName || ''} `)

    const phone = computed(() => `${currentOrder?.shippingAddress?.phone || ''}`)
    const missingPhone = computed(() => !currentOrder?.shippingAddress?.phone || '')
    const email = computed(() => `${currentOrder?.customer?.email || ''}`)

    const shippingAddress = computed(() => {
      const obj = currentOrder?.shippingAddress
      let address = ''

      if (obj?.address1 && obj?.address1 !== '') {
        address += `${obj?.address1}, `
      }

      if (obj?.address2 && obj?.address2 !== '') {
        address += `${obj?.address2}, `
      }

      address += `${obj?.city}, ${obj?.state?.code} ${obj?.postcode}`

      if (obj?.country && obj?.country?.code2) {
        address += `, ${obj?.country?.code2}`
      }

      return address.replace('null', '').replace('undefined', '')
    })

    return {
      currentOrder,
      fullName,
      phone,
      email,
      shippingAddress,
      getFbmOrder,
      missingPhone,
      isCurrentOrderShipped,
    }
  },
  data() {
    return {
      addressForm: {
        firstName: '',
        lastName: '',
        postcode: '',
        address1: '',
        address2: '',
        phone: '',
        city: '',
        state: '',
        country: '',
      },
      isEditable: false,
    }
  },
  watch: {
    'currentOrder.id'() {
      this.isEditable = false
    },
  },
  methods: {
    openAddressForm() {
      this.isEditable = true
      this.addressForm.firstName = this.currentOrder?.shippingAddress?.firstName
      this.addressForm.lastName = this.currentOrder?.shippingAddress?.lastName
      this.addressForm.phone = this.currentOrder?.shippingAddress?.phone
      this.addressForm.postcode = this.currentOrder?.shippingAddress?.postcode
      this.addressForm.address1 = this.currentOrder?.shippingAddress?.address1
      this.addressForm.address2 = this.currentOrder?.shippingAddress?.address2
      this.addressForm.city = this.currentOrder?.shippingAddress?.city
      this.addressForm.state = this.currentOrder?.shippingAddress?.state?.code
      this.addressForm.country = this.currentOrder?.shippingAddress?.country?.code2
    },
    async updateShippingAddress() {
      const formRef = this.$refs.addressFormRef.instance
      const validationResult = formRef.validate()
      if (validationResult.isValid) {
        await fbmService.updateShippingAddress(this.currentOrder.id, this.addressForm)
        await this.getFbmOrder(this.currentOrder.id)
        this.isEditable = false
      }
    },
  },
}
</script>

<style lang="css" scoped>
.text-button {
  margin-left: 16px;
  transition: color 0.3s ease;
}
.text-button:hover {
  color: #66bcf6;
}
p {
  margin: 4px;
}
span {
  transition: color 0.3s ease;
}
span:hover {
  color: #66bcf6;
}
</style>
